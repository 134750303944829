<template>
  <el-dialog
    title="下发"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="45%">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="100px">
      <el-form-item label="指标名称" prop="orkName">
        <label key="dataForm.orkName">{{dataForm.orkName}}</label>
      </el-form-item>
      <el-form-item label="指标年份"  prop="orkYear">
        <label key="dataForm.orkYear">{{dataForm.orkYear}}</label>
      </el-form-item>
      <el-form-item label="新增用户" prop="addUserNumber" >
      <el-input-number  v-model="dataForm.addUserNumber"  :min="0" placeholder="请输入新增用户数"></el-input-number>
      </el-form-item>
      <el-form-item label="增值收入" prop="valueAddedIncome" >
        <el-input-number  v-model="dataForm.valueAddedIncome"  :min="0" placeholder="请输入增值收入数"></el-input-number>
      </el-form-item>
      <el-form-item label="大众业务收入" prop="publicBusinessIncome" >
        <el-input-number  v-model="dataForm.publicBusinessIncome"  :min="0" placeholder="请输入大众业务收入数"></el-input-number>
      </el-form-item>
      <el-form-item label="5G用户发展用户" prop="developmentUser" >
        <el-input-number  v-model="dataForm.developmentUser"  :min="0" placeholder="请输入5G用户发展用户数"></el-input-number>
      </el-form-item>
      <el-form-item label="已下发区域">
        <label key="dataForm.issueFiliale">{{dataForm.issueFiliale}}</label>
      </el-form-item>
      <el-form-item label="下级区域" prop="areaCodes">
        <el-select v-model="dataForm.areaCodes" multiple filterable clearable style="width: 300px">
          <el-option
            v-for="item in selfAreaCodeList"
            :key="item.code"
            :label="item.name"
            :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="填写人" prop="userName">
        <el-input v-model="dataForm.userName" disabled placeholder="填写人" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="下发时间" prop="transDate">
        <el-date-picker
          v-model="dataForm.transDate"
          type="date"
          value-format='yyyy-MM-dd'
          placeholder="选择日期"
          style="width: 300px"
          >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit(2)" :disabled="isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getIssuedInfo , transmitSave } from '@/api/dataflow/olxpokrmanagemain.js'
import {dateFormat} from "../../../../utils";
  export default {
    data () {
      return {
        visible: false,
        isOnSubmit: false,
        flag:'',
        selfAreaCodeList:[],
        dataForm: {
          id: null,
          orkName: '',
          orkState: '',
          okrType: '',
          okrTarget: '',
          addUserNumber: '',
          valueAddedIncome: '',
          publicBusinessIncome: '',
          developmentUser: '',
          orkYear: '',
          userId: '',
          areaCode: '',
          fillDate: '',
          transDate: '',
          execDate: '',
          userName:'',
          issueFiliale:'',
          areaCodes:[]
        },
        dataRule: {
          userName: [
            { required: true, message: '填写人不能为空', trigger: 'blur' }
          ],
          areaCode: [
            { required: true, message: '下级区域不能为空', trigger: 'blur' }
          ],
          transDate: [
            { required: true, message: '下发时间不能为空', trigger: 'blur' }
          ],
          areaCodes:[
            { required: true, message: '下级区域不能为空', trigger: 'change' }
          ],
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id
        this.visible = true
        this.isOnSubmit = false
        this.dataForm.fillDate = dateFormat(new Date,'yyyy-MM-dd')
        this.dataForm.orkYear = dateFormat(new Date,'yyyy')
        this.dataForm.userName = this.$cookie.get("Username");
        //获取登录时的ID
        const code = '1000'
        this.getSelfAreaCode(code)
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            getIssuedInfo(id).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.orkName = data.data.orkName
                this.dataForm.addUserNumber = data.data.addUserNumber
                this.dataForm.valueAddedIncome = data.data.valueAddedIncome
                this.dataForm.publicBusinessIncome = data.data.publicBusinessIncome
                this.dataForm.developmentUser = data.data.developmentUser
                this.dataForm.orkYear = data.data.orkYear
                this.dataForm.userName = data.data.userName
                this.dataForm.transDate = data.data.transDate
                this.dataForm.issueFiliale = data.data.issueFiliale
              }
            }).catch((err) => {
              this.$message.error(err)
            })
          }
        })
      },
      //更改状态
      changeState(){
        //var issue = '02';
        this.dataForm.orkState = '02';

      },
      //所属区域
      getSelfAreaCode(code) {
        this.$http({
            url: this.$http.adornUrl(`/dataflow/olxpokrmanagemain/getFiliale`),
            method: "get",
            params: this.$http.adornParams({
            'id': code,
            'parentId':this.dataForm.id
          })
          }).then(({ data }) => {
            this.selfAreaCodeList = data.data
          });
      },
      // 表单提交
      dataFormSubmit (a) {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {

            if(a==1){
              this.dataForm.orkState = '02';
            }

          	this.isOnSubmit = true
            const data_ = {
              'id': this.dataForm.id,
			        'orkName': this.dataForm.orkName,
			        'orkState': this.dataForm.orkState,
			        'addUserNumber': this.dataForm.addUserNumber,
			        'valueAddedIncome': this.dataForm.valueAddedIncome,
			        'publicBusinessIncome': this.dataForm.publicBusinessIncome,
			        'developmentUser': this.dataForm.developmentUser,
			        'orkYear': this.dataForm.orkYear,
			        'userName': this.dataForm.userName,
			        'areaCodes': this.dataForm.areaCodes,
			        'transDate': this.dataForm.transDate,
            }
            transmitSave(data_).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 500
                })
                this.visible = false
                this.$emit('refreshDataList')
              } else {
                this.$message.error(data.msg)
              }
            }).catch((err) => {
              this.$message.error(err)
            }).finally(()=>{
              this.isOnSubmit = false
            })
          }
        })
      }
    }
  }
</script>
