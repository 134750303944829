import http from '@/utils/httpRequest'

// 列表获取
export function getList(params) {
  return http({
          url: http.adornUrl('/dataflow/olxpokrmanagemain/list'),
          method: 'get',
          params: params
        })
} 

// 查看列表获取
export function getViewList(params) {
        return http({
                url: http.adornUrl('/dataflow/olxpokrmanagemain/viewList'),
                method: 'get',
                params: params
              })
      } 
// 删除数据
export function deleteData(ids = []) {
	return http({
            url: http.adornUrl("/dataflow/olxpokrmanagemain/delete"),
            method: "post",
            data: http.adornData(ids, false)
          })
}

// 根据ID 获取
export function getById(id) {
	return http({
        url: http.adornUrl(`/dataflow/olxpokrmanagemain/info/${id}`),
        method: "get"
        })
}

// 根据ID 获取
export function getByIds(id) {
	return http({
        url: http.adornUrl(`/dataflow/olxpokrmanagemain/infos/${id}`),
        method: "get"
        })
}
 
// 更新或新增
export function saveOrUpdate(data) {
	return http({
            url: http.adornUrl(
              `/dataflow/olxpokrmanagemain/${!data.id ? "save" : "update"}`
            ),
            method: "post",
            data: data
        })
}

//下发
export function transmitSave(data) {
	return http({
            url: http.adornUrl(
              `/dataflow/olxpokrmanagemain/transmitSave`
            ),
            method: "post",
            data: data
        })
}
//修改状态
export function getUpdateOrkState(id) {
    return http({
        url: http.adornUrl(`/dataflow/olxpokrmanagemain/getUpdateOrkState/${id}`),
        method: "get"
    })
}


// 根据ID 获取
export function getIssuedInfo(id) {
	return http({
        url: http.adornUrl(`/dataflow/olxpokrmanagemain/issuedInfo/${id}`),
        method: "get"
        })
}